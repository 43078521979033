import React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Layout from '../../../components/Default/Layout'
import ServiceHeader from '../../../components/Services/ServiceOverview/ServiceHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import Index from '../../../../content/accordion/sluzby'

import { graphql } from 'gatsby'
import {
  SingleAccordion,
  Description,
} from '../../../components/Services/ServiceOverview/SingleAccordion'

export const Dane = ({ data: { page }, pageContext }) => {
  const { frontmatter } = page
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <>
      <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
        metaCrumb={crumbs}
      >
        <ServiceHeader
          title={frontmatter.title}
          backgroundImage={
            !!frontmatter.image.childImageSharp
              ? frontmatter.image.childImageSharp.fluid.src
              : frontmatter.image
          }
        />
        <Breadcrumb crumbs={crumbs} crumbLabel={'Daně'} />
        <div className="Paragraph--Wrapper container">
          <p>
            Potřebujete zkonzultovat daňový dopad plánované transakce, podat
            přiznání či kontrolní hlášení, zastoupit na finančním úřadě nebo
            řešíte jiný problém spojený s daněmi? Obraťte se na nás- poradíme s
            přímými i nepřímými daněmi. Poskytujeme také rychlé konzultace po
            telefonu. Samozřejmostí je pro nás i analýza převodních cen.
          </p>
          <SingleAccordion
            items={Index.dane.links.map(item => ({
              title: item.name,
              description: <Description {...item} />,
            }))}
          />
        </div>
      </Layout>
    </>
  )
}
export default Dane

export const pageQuery = graphql`
  query Dane {
    page: markdownRemark(frontmatter: { slug: { eq: "sluzby/dane" } }) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
